import { apiClient } from "@api/config";
import { Country, FeatureEnum, PermissionType } from "@api/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

export interface GetOrganizationQueryOrganization {
    id: string;
    profilePicture: string;
    name: string;
    isOwnedByCurrentUser: boolean;
    permissions: PermissionType[];
    premiumCredits: number;
    featureToggles: {
        feature: FeatureEnum;
        enabled: boolean;
    }[];
    country: Country;
}

export const useGetOrganizationQuery = (
    orgId: string,
    options?: Omit<
        UseQueryOptions<
            AxiosResponse<GetOrganizationQueryOrganization>,
            AxiosError
        >,
        "queryKey"
    >,
) =>
    useQuery(
        [useGetOrganizationQuery.name, orgId],
        () =>
            apiClient.get<GetOrganizationQueryOrganization>(
                `/organizations/${orgId}`,
            ),
        options,
    );
