import { Country, PurchasePaymentMethod } from "@api/types";
import Argentina from "@assets/images/argentina.png";
import Bolivia from "@assets/images/bolivia.png";
import Chile from "@assets/images/chile.png";
import Colombia from "@assets/images/colombia.png";
import CostaRica from "@assets/images/costa_rica.png";
import Ecuador from "@assets/images/ecuador.png";
import Guatemala from "@assets/images/guatemala.png";
import Mexico from "@assets/images/mexico.png";
import Panama from "@assets/images/panama.png";
import Paraguay from "@assets/images/paraguay.png";
import Peru from "@assets/images/peru.png";
import Uruguay from "@assets/images/uruguay.png";

const countriesInfo: Record<
    Country,
    {
        code: string;
        symbol: string;
        image: string;
        name: string;
        emoji: string;
        fiscalIdLabel: string;
        defaultFiscalIdRequired: boolean;
        phoneCode: number;
        bankInfo: {
            accountNumberLabel: string;
            accountNumberPlaceholder: string;
            fiscalIdRequired: boolean;
            accountTypeRequired: boolean;
        };
    }
> = {
    [Country.Argentina]: {
        code: "ARS",
        symbol: "$",
        image: Argentina,
        name: "Argentina",
        emoji: "🇦🇷",
        fiscalIdLabel: "DNI",
        defaultFiscalIdRequired: true,
        phoneCode: 54,
        bankInfo: {
            accountNumberLabel: "CBU/CVU",
            accountNumberPlaceholder: "1234567890123456789012",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
    [Country.Bolivia]: {
        code: "BOB",
        symbol: "Bs. ",
        image: Bolivia,
        name: "Bolivia",
        emoji: "🇧🇴",
        fiscalIdLabel: "CI",
        defaultFiscalIdRequired: true,
        phoneCode: 591,
        bankInfo: {
            accountNumberLabel: "Número de cuenta",
            accountNumberPlaceholder: "12345678901234567890",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
    [Country.Chile]: {
        code: "CLP",
        symbol: "$",
        image: Chile,
        name: "Chile",
        emoji: "🇨🇱",
        fiscalIdLabel: "RUT",
        defaultFiscalIdRequired: false,
        phoneCode: 56,
        bankInfo: {
            accountNumberLabel: "Número de cuenta",
            accountNumberPlaceholder: "12345678901234567890",
            fiscalIdRequired: true,
            accountTypeRequired: false,
        },
    },
    [Country.Colombia]: {
        code: "COP",
        symbol: "$",
        image: Colombia,
        name: "Colombia",
        emoji: "🇨🇴",
        fiscalIdLabel: "CC",
        defaultFiscalIdRequired: false,
        phoneCode: 57,
        bankInfo: {
            accountNumberLabel: "Número de cuenta",
            accountNumberPlaceholder: "12345678901234567890",
            fiscalIdRequired: false,
            accountTypeRequired: true,
        },
    },
    [Country.Mexico]: {
        code: "MXN",
        symbol: "$",
        image: Mexico,
        name: "México",
        emoji: "🇲🇽",
        fiscalIdLabel: "INE",
        defaultFiscalIdRequired: false,
        phoneCode: 52,
        bankInfo: {
            accountNumberLabel: "CLABE",
            accountNumberPlaceholder: "123456789012345678",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
    [Country.Peru]: {
        code: "PEN",
        symbol: "S/ ",
        image: Peru,
        name: "Perú",
        emoji: "🇵🇪",
        fiscalIdLabel: "DNI",
        defaultFiscalIdRequired: false,
        phoneCode: 51,
        bankInfo: {
            accountNumberLabel: "CCI",
            accountNumberPlaceholder: "12345678901234567890",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
    [Country.Uruguay]: {
        code: "UYU",
        symbol: "$",
        image: Uruguay,
        name: "Uruguay",
        emoji: "🇺🇾",
        fiscalIdLabel: "CI",
        defaultFiscalIdRequired: false,
        phoneCode: 598,
        bankInfo: {
            accountNumberLabel: "Número de cuenta",
            accountNumberPlaceholder: "1234567891",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
    [Country.CostaRica]: {
        code: "CRC",
        symbol: "₡",
        image: CostaRica,
        name: "Costa Rica",
        emoji: "🇨🇷",
        fiscalIdLabel: "Cédula Identidad",
        defaultFiscalIdRequired: false,
        phoneCode: 506,
        bankInfo: {
            accountNumberLabel: "Número de cuenta",
            accountNumberPlaceholder: "12345678901234567890",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
    [Country.Ecuador]: {
        code: "USD",
        symbol: "$",
        image: Ecuador,
        name: "Ecuador",
        emoji: "🇪🇨",
        fiscalIdLabel: "Cédula",
        defaultFiscalIdRequired: false,
        phoneCode: 593,
        bankInfo: {
            accountNumberLabel: "Número de cuenta",
            accountNumberPlaceholder: "123456789012345678",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
    [Country.Guatemala]: {
        code: "GTQ",
        symbol: "Q",
        image: Guatemala,
        name: "Guatemala",
        emoji: "🇬🇹",
        fiscalIdLabel: "DPI",
        defaultFiscalIdRequired: false,
        phoneCode: 502,
        bankInfo: {
            accountNumberLabel: "Número de cuenta",
            accountNumberPlaceholder: "12345678901234567890",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
    [Country.Panama]: {
        code: "USD",
        symbol: "$",
        image: Panama,
        name: "Panamá",
        emoji: "🇵🇦",
        fiscalIdLabel: "Cédula",
        defaultFiscalIdRequired: false,
        phoneCode: 507,
        bankInfo: {
            accountNumberLabel: "Número de cuenta",
            accountNumberPlaceholder: "12345678901234567890",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
    [Country.Paraguay]: {
        code: "PYG",
        symbol: "₲",
        image: Paraguay,
        name: "Paraguay",
        emoji: "🇵🇾",
        fiscalIdLabel: "CI",
        defaultFiscalIdRequired: false,
        phoneCode: 595,
        bankInfo: {
            accountNumberLabel: "Número de cuenta",
            accountNumberPlaceholder: "12345678901234567890",
            fiscalIdRequired: false,
            accountTypeRequired: false,
        },
    },
};

export const getCurrencyCodeByCountry = (country: Country) =>
    countriesInfo[country].code;

export const getCurrencySymbolByCountry = (country: Country) =>
    countriesInfo[country].symbol;

export const getImageByCountry = (country: Country) =>
    countriesInfo[country].image;

export const getNameByCountry = (country: Country) =>
    countriesInfo[country].name;

export const getEmojiByCountry = (country: Country) =>
    countriesInfo[country].emoji;

export const getFiscalIdLabelByCountry = (country: Country) =>
    countriesInfo[country].fiscalIdLabel;

export const getPhoneCodeByCountry = (country: Country) =>
    countriesInfo[country].phoneCode;

export const getBankInfoByCountry = (country: Country) =>
    countriesInfo[country].bankInfo;

export const getFiscalIdRequiredByCountry = (country: Country) =>
    countriesInfo[country].defaultFiscalIdRequired;

export const getPaymentMethodsByCountry = (country: Country) => {
    switch (country) {
        case Country.Argentina:
            return [
                PurchasePaymentMethod.MercadoPago,
                PurchasePaymentMethod.Uala,
                PurchasePaymentMethod.BankTransfer,
                PurchasePaymentMethod.Unicobros,
            ];
        case Country.Bolivia:
        case Country.Chile:
        case Country.Colombia:
        case Country.CostaRica:
        case Country.Ecuador:
        case Country.Guatemala:
        case Country.Mexico:
        case Country.Panama:
        case Country.Paraguay:
        case Country.Peru:
        case Country.Uruguay:
            return [
                PurchasePaymentMethod.BankTransfer,
                PurchasePaymentMethod.ManualMercadoPago,
            ];
    }
};
