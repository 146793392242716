import "@assets/fonts/fonts.css";
import { defineStyle, extendTheme } from "@chakra-ui/react";
import { getAlertTheme } from "./alert";
import { getMenuTheme } from "./menu";
import { getPopoverTheme } from "./popover";

export const theme = extendTheme({
    styles: {
        global: () => ({
            body: {
                bg: "black",
                overflowY: "auto",
                overflowX: "hidden",
                width: "100vw",
            },
        }),
    },
    breakpoints: {
        "2xl": "1700px",
    },
    fonts: {
        body: "Inter, sans-serif",
        heading: "Inter, sans-serif",
        headingReports: "Barlow Condensed, sans-serif",
    },
    colors: {
        primary: "#C16DFF",
        "primary.dark": "#8749b3",
        "primary.100": "#1B0C26",
        secondary: "black",
        "secondary.500": "#1C1C1C",
        "secondary.600": "#0F0F0F",
        dark_purple: "#371451",
        darker_purple: "#1B0C26",
        dark_gray: "#0f0f0f",
        light_gray: "#161618",
        lighter_gray: "#282828",
        borders: "#222222",
    },
    components: {
        Button: {
            baseStyle: {
                fontFamily: "body",
                boxShadow: "none !important",
                textDecoration: "none !important",
            },
            variants: {
                primary: {
                    bgColor: "dark_purple",
                    colorScheme: "purple",
                    rounded: "md",
                    color: "primary",
                    borderWidth: 1,
                    borderColor: "#371451",
                    _hover: {
                        bgColor: "#5A2085",
                    },
                },
                secondary: {
                    bgColor: "transparent",
                    colorScheme: "whiteAlpha",
                    rounded: "md",
                    borderWidth: 1,
                    borderColor: "primary",
                    color: "primary",
                },
                striking: {
                    bgColor: "primary",
                    colorScheme: "purple",
                    rounded: "md",
                    color: "black",
                    _hover: {
                        bgColor: "primary.dark !important",
                    },
                },
            },
            sizes: {
                xs: {
                    py: ".8rem",
                },
                sm: {
                    px: 10,
                },
                md: {
                    px: 10,
                },
            },
        },
        Heading: {
            baseStyle: {
                color: "white",
            },
            variants: {
                landingHeading: defineStyle({
                    fontFamily: "landingHeading",
                    lineHeight: "100%",
                }),
            },
        },
        Text: {
            baseStyle: {
                color: "white",
            },
        },
        FormLabel: {
            baseStyle: {
                color: "white",
            },
        },
        Modal: {
            baseStyle: {
                dialog: {
                    bgColor: "light_gray",
                    rounded: "md",
                },
                header: {
                    borderBottomWidth: 1,
                    w: "full",
                    textAlign: "center",
                    borderColor: "gray.700",
                    color: "white",
                },
                closeButton: {
                    color: "white",
                },
                body: {
                    p: 5,
                },
            },
        },
        Input: {
            baseStyle: {
                field: {
                    rounded: "md",
                    bgColor: "dark_gray !important",
                    borderWidth: "0 !important",
                    boxShadow: "0 0 0 1px #222222 !important",
                    color: "white",
                    _focus: {
                        boxShadow: "0 0 0 1px #C16DFF !important",
                    },
                    "&::-webkit-calendar-picker-indicator": {
                        filter: "invert(1)",
                    },
                },
                addon: {
                    rounded: "md",
                    bgColor: "dark_gray !important",
                    color: "gray.500",
                    borderWidth: "0 !important",
                    boxShadow: "0 0 0 1px #222222 !important",
                },
            },
        },
        Divider: {
            baseStyle: {
                borderColor: "gray.600",
                width: "full",
            },
        },
        Tabs: {
            baseStyle: {
                root: {
                    bgColor: "light_gray",
                    py: 3,
                    px: 8,
                    rounded: "md",
                    borderBottomColor: "gray.700",
                },
                tablist: {
                    color: "white",
                },
                tab: {
                    _selected: {
                        color: "white",
                        borderBottomColor: "primary !important",
                    },
                    _active: {
                        background: "none",
                    },
                    flex: 1,
                },
            },
        },
        Textarea: {
            variants: {
                outline: defineStyle({
                    borderColor: "borders",
                    rounded: "md",
                    background: "dark_gray",
                    resize: "none",
                    color: "white",
                    _focus: {
                        boxShadow: "0 0 0 1px #C16DFF !important",
                    },
                    _hover: {
                        borderColor: "none !important",
                    },
                }),
            },
        },
        Spinner: {
            baseStyle: {
                color: "primary",
            },
        },
        Alert: getAlertTheme(),
        Menu: getMenuTheme(),
        Popover: getPopoverTheme(),
    },
});
