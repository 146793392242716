import { apiClient } from "@api/config";
import { EventStatus, ProductType, PurchasePaymentMethod } from "@api/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export interface AdminGetEventResponse {
    id: string;
    name: string;
    description: string;
    address: string;
    slug: string;
    startDateTime: string;
    endDateTime: string;
    flyers: {
        id: string;
        url: string;
        isVideo: boolean;
        order: number;
    }[];
    tags: {
        name: string;
    }[];
    products: {
        id: string;
        name: string;
        price: number;
        initialStock: number;
        description: string;
        maxPerPurchase: number;
        productType: ProductType;
        open: boolean;
        active: boolean;
        order: number;
    }[];
    minManAge: number;
    minWomenAge: number;
    hasOnlineSale: boolean;
    status: EventStatus;
    whatsAppEnabled: boolean;
    allowedPaymentMethods: PurchasePaymentMethod[];
    fiscalIdRequired: boolean;
}

interface AdminGetEventRequest {
    eventId: string;
}

export const useAdminGetEventQuery = (
    request: AdminGetEventRequest,
    options?: Omit<UseQueryOptions<AdminGetEventResponse>, "queryKey">,
) =>
    useQuery(
        [useAdminGetEventQuery.name, request.eventId],
        async () => {
            const { data } = await apiClient.get<AdminGetEventResponse>(
                `/admin/events/${request.eventId}`,
            );
            return data;
        },
        options,
    );
