import { Box, chakra, Image } from "@chakra-ui/react";
import {
    replaceImageSrc,
    ResizedDimensions,
    withResizedDimensions,
} from "@utils/files";
import { useMemo } from "react";

interface Props {
    url: string;
    isVideo: boolean;
    alt: string;
    relativeToParent?: boolean;
}

const FlyerBackground = ({ url, isVideo, alt, relativeToParent }: Props) => {
    const resizedUrl = useMemo(
        () => withResizedDimensions(url, ResizedDimensions.XS),
        [url],
    );

    return (
        <Box
            pos={relativeToParent ? "absolute" : "fixed"}
            bottom={0}
            left={0}
            w="full"
            h="full"
            filter="auto"
            blur="2xl"
            opacity=".5"
            overflow="hidden"
            zIndex={-1}
            userSelect="none"
            _after={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                bgGradient:
                    "linear(to-t, black, rgba(0, 0, 0, .9), rgba(0, 0, 0, .7), transparent)",
                zIndex: 1,
            }}
        >
            {isVideo ? (
                <chakra.video
                    h="full"
                    w="full"
                    muted
                    autoPlay
                    playsInline
                    loop
                    objectFit={"cover"}
                >
                    <source src={resizedUrl} />
                    {/* Backup in case the resized file has not been created yet */}
                    <source src={url} />
                </chakra.video>
            ) : (
                <Image
                    src={resizedUrl}
                    objectFit="cover"
                    alt={alt}
                    w="full"
                    h="full"
                    onError={(e) => replaceImageSrc(e, url)}
                />
            )}
        </Box>
    );
};

export default FlyerBackground;
