import { apiClient } from "@api/config";
import { Country, PurchasePaymentMethod } from "@api/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export interface UseGetEventResponse {
    name: string;
    banner: {
        url: string;
        isVideo: boolean;
    };
    whatsAppEnabled: boolean;
    fiscalIdRequired: boolean;
    serviceChargeEnabled: boolean;
    paymentMethods: {
        paymentMethod: PurchasePaymentMethod;
        isAllowed: boolean;
    }[];
    startDateTime: string;
    endDateTime: string;
    country: Country;
    organization: {
        name: string;
        email: string;
    };
    description: string;
}

interface UseGetEventRequest {
    id: string;
}

export const useGetEventQuery = (
    request: UseGetEventRequest,
    options?: Omit<UseQueryOptions<UseGetEventResponse>, "queryKey">,
) =>
    useQuery(
        [useGetEventQuery.name],
        async () => {
            const res = await apiClient.get<UseGetEventResponse>(
                `/events/${request.id}`,
            );
            return res.data;
        },
        options,
    );
