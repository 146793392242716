import { Country } from "@api/types";
import {
    GetOrganizationQueryOrganization,
    useGetOrganizationQuery,
} from "@api/useCases/organizations/queries/getOrganization/useGetOrganizationQuery";
import { Center, Heading } from "@chakra-ui/react";
import { createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";
import { AuthContext } from "./AuthContext";

interface CurrentOrganizationContextProps {
    organization: GetOrganizationQueryOrganization;
}

export const CurrentOrganizationContext =
    createContext<CurrentOrganizationContextProps>(
        {} as CurrentOrganizationContextProps,
    );

export const CurrentOrganizationProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const {
        data: res,
        isError,
        isLoading,
        error,
    } = useGetOrganizationQuery(user?.activeOrganization?.id ?? "", {
        enabled: !!user?.activeOrganization?.id,
    });

    useEffect(() => {
        if (
            isError &&
            error.response &&
            (error.response.status === 401 || error.response.status === 403)
        ) {
            navigate("/auth");
        }
    }, [isError, error]);

    if (isLoading && !!user?.activeOrganization?.id) return <LoadingOverlay />;

    if (isError)
        return (
            <Center w="full" h="full" p={5}>
                <Heading textAlign={"center"}>
                    Ha ocurrido un error, por favor intenta de nuevo más tarde.
                    Si el problema persiste ponte en contacto con el soporte
                    técnico de {import.meta.env.VITE_COMPANY_NAME}.
                </Heading>
            </Center>
        );

    return (
        <CurrentOrganizationContext.Provider
            value={{
                organization: res?.data ?? {
                    // We should never get here, but just for TS safety
                    id: "",
                    profilePicture: "",
                    name: "",
                    isOwnedByCurrentUser: false,
                    premiumCredits: 0,
                    permissions: [],
                    featureToggles: [],
                    country: Country.Argentina,
                },
            }}
        >
            {children}
        </CurrentOrganizationContext.Provider>
    );
};

export const useCurrentOrganizationContext = () =>
    useContext(CurrentOrganizationContext);
