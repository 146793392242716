import { useEffect } from "react";

interface Props {
    children: React.ReactNode;
    suffix?: string;
}

const PageTitle = ({
    children,
    suffix = ` | ${import.meta.env.VITE_COMPANY_NAME}`,
}: Props) => {
    useEffect(() => {
        const titleString = Array.isArray(children)
            ? children.join("") // Flatten array into a single string
            : typeof children === "string"
            ? children
            : String(children);

        const previousTitle = document.title;
        const previousMetaTitle =
            document
                .querySelector("meta[name='title']")
                ?.getAttribute("content") || "";
        const previousOgTitle =
            document
                .querySelector("meta[property='og:title']")
                ?.getAttribute("content") || "";
        const previousTwitterTitle =
            document
                .querySelector("meta[name='twitter:title']")
                ?.getAttribute("content") || "";

        document.title = titleString + suffix;

        const updateMetaTag = (selector: string, content: string) => {
            const metaTag = document.querySelector(selector);
            if (metaTag) {
                metaTag.setAttribute("content", content);
            }
        };

        updateMetaTag("meta[name='title']", titleString + suffix);
        updateMetaTag("meta[property='og:title']", titleString + suffix);
        updateMetaTag("meta[name='twitter:title']", titleString + suffix);

        return () => {
            document.title = previousTitle;
            updateMetaTag("meta[name='title']", previousMetaTitle);
            updateMetaTag("meta[property='og:title']", previousOgTitle);
            updateMetaTag("meta[name='twitter:title']", previousTwitterTitle);
        };
    }, [children, suffix]);

    return null;
};

export default PageTitle;
